.filters-menu{
  border-top: 1px solid;
  a{
    margin-right: 17px;
    text-decoration: none;
  }
}


.tags-menu{
  border-bottom: none;
}

.tag{
  display: inline-block;
  align-items: center;
  height: 100%;
  justify-content: center;
  background-color: #ffdcf2;

}
